import axios from 'axios';
import { 
  getListAllParticipanteUri,
  createPruebaUri
} from '../constants/ApiRoutes';

//Actions
const GET_PARTICIPANTE = 'GET_PARTICIPANTE'
const SET_PRUEBA = 'SET_PRUEBA'

//Reducer
const INIT_STATE = {
  participante: {},
  prueba: null
}

export default function reducer(state = INIT_STATE, action) {
  switch(action.type) {
    case GET_PARTICIPANTE:
      return {
        ...state,
        participante: action.payload
      }
    case SET_PRUEBA:
      return {
        ...state,
        prueba: action.payload
      }
      default:
        return state;
  }
}

//Action Creators
export function getParticipante(payload) {
  return { 
    type: GET_PARTICIPANTE,
    payload
  }
}
export function setPrueba(payload) {
  return { 
    type: SET_PRUEBA,
    payload
  }
}

//Side Effects
export const fetchParticipante = (tipo_documento = '', numero_documento = '') => async (dispatch) => {
  try {
    const res = await axios.get(getListAllParticipanteUri(), {
      params: {
        tipo_documento,
        numero_documento
      }
    })
    dispatch(getParticipante(res.data))
  } catch (error) {
    console.log(error)
  }
}

export const createPrueba = (prueba) => async (dispatch) => {
  try {
    const res = await axios.post(createPruebaUri(), prueba)
    dispatch(setPrueba(res.data))
  } catch (error) {
    console.log(error)
  }
}