import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import Nav from './components/Nav'
import DatosPersonales from './components/DatosPersonales'
import Cuadernillo from './components/Cuadernillo'
import PDP from './components/PDP'

import Swal from 'sweetalert2';
import blue from '@material-ui/core/colors/blue';

import {useDispatch, useSelector} from 'react-redux'
import {getAllIPV} from './redux/IPVDuck'
import {getAllPDP} from './redux/PDPDuck'
import {createPrueba} from './redux/ParticipanteDuck'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  container: {
    marginTop: theme.mixins.toolbar.minHeight,
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.primary,
  },
  stepper: {
    marginTop: theme.mixins.toolbar.minHeight,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function getSteps() {
  return ['Datos Personales', 'IPV', 'PDP'];
}

function Pruebas() {
  const classes = useStyles();
  const [datosPersonales, setDatosPersonales] = useState({
    /*nombre: 'Devora Reyes',
    edad: 28,
    numero_documento: "15489532",
    telefono: "970943880",
    correo: "dreyes@novax.com.pe",
    nivel_estudios: 'Universitario',
    actividad_actual: 'Contador',*/
  });
  const [respuestasIPV, setRespuestasIPV] = useState([]);
  const [respuestasPDP, setRespuestasPDP] = useState([]);
  const [validateDatosPersonales, setValidateDatosPersonales] = useState(false);
  const [validateIPV, setValidateIPV] = useState(false);
  const [validatePDP, setValidatePDP] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const dispatch = useDispatch();
  const IPVList = useSelector(state => state.IPV.IPVList);
  const PDPList = useSelector(state => state.PDP.PDPList);
  const pruebaParticipante = useSelector(state => state.participante.prueba);

  const handleNext = () => {
    if (IPVList.length <= 0) {
      dispatch(getAllIPV())
    }

    if (PDPList.length <= 0) {
      dispatch(getAllPDP())
    }
    
    switch (activeStep) {
      case 0: 
        setValidateDatosPersonales(true);
        break;
      case 1: 
        setValidateIPV(true);
        break;
      case 2: 
        setValidatePDP(true);
        break;
      default: 
    }

  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSaveDatosPersonales = (data) => {
    setDatosPersonales(data);
  };

  const handleRespuestasIPV = (data) => {
    setRespuestasIPV(data);
  };

  const handleRespuestasPDP = (data) => {
    setRespuestasPDP(data);
  };

  useEffect(() => {
    if (pruebaParticipante) {
      setOpenBackdrop(false);

      if(pruebaParticipante.error) {
        Swal.fire({
          title: "ERROR!!",
          text: pruebaParticipante.error,
          icon: 'error',
          confirmButtonText: 'ACEPTAR',
          confirmButtonColor: blue[500],
        });
      }
      else {
        Swal.fire({
          title: "Prueba Enviada",
          icon: 'success',
          confirmButtonText: 'ACEPTAR',
          confirmButtonColor: blue[500],
        });
        setActiveStep(3);
      }
    }
  }, [pruebaParticipante])

  const getStepContent = (step) => {
    switch (step) {
      case 0: 
        return <DatosPersonales
          data={datosPersonales}
          handleSaveData={handleSaveDatosPersonales}
          validate={validateDatosPersonales} 
          setValidate={(validate) => setValidateDatosPersonales(validate)}
          setValid={(valid) => {
            if(valid) {
              setActiveStep(1);
            }
          }} />;
      case 1:
        return <Cuadernillo 
          items={IPVList}
          data={respuestasIPV}
          validate={validateIPV} 
          setValidate={(validate) => setValidateIPV(validate)}
          setValid={async(valid, data) => {
            if(valid) {
              await setRespuestasIPV(data);
              setActiveStep(2);
            }
          }} />;
      case 2:
        return <PDP 
          items={PDPList}
          data={respuestasPDP}
          handleSaveData={handleRespuestasPDP}
          validate={validatePDP} 
          setValidate={(validate) => setValidatePDP(validate)}
          setValid={(valid, cuadrantes) => {
            if(valid) {
              const prueba = {
                participante: datosPersonales,
                ipv: respuestasIPV,
                pdp: cuadrantes
              };
              setOpenBackdrop(true);
              dispatch(createPrueba(prueba));
            }
          }} />;
      default:
        return 'Unknown step';
    }
  }

  return (
    <div className={classes.root}>
      <Nav />

      <Box p={2} className={classes.container}>
        
        <Grid container justify='center'>
          <Grid item sm={11} lg={6}>
            <Paper className={classes.paper}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Paper>
          </Grid>
        </Grid>

        <div>
          {activeStep === steps.length 
          ? (
              <Grid container justify='center'>
                <Grid item sm={11} lg={6}>
                  <Paper className={classes.paper}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box mb={2}>
                          <Typography variant='h5' align='center' gutterBottom>
                            FIN DE LA PRUEBA
                          </Typography>
                          <Typography variant='body1' align='justify' gutterBottom>
                            Su prueba se ha enviado satisfactoriamente, estaremos realizando las validaciones y
                            nos estaremos comunicando con usted lo mas pronto posible.
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            ) 
          : (
              <div>
                {getStepContent(activeStep)}

                <Grid container justify='center'>
                  <Grid item sm={11} lg={6}>
                    <Paper className={classes.paper}>
                      <Button 
                        disabled={activeStep === 0} 
                        onClick={handleBack} 
                        className={classes.button}
                      >
                        Atrás
                      </Button>

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                      >
                        {activeStep === steps.length - 1 ? 'Enviar Prueba' : 'Siguiente'}
                      </Button>
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            )}
        </div>
      </Box>

      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default Pruebas;
