import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Box,
  Typography
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    textAlign: 'justify',
    color: theme.palette.text.primary,
  },

}));

function InstruccionesPDP() {
  const classes = useStyles();

  return (
    <div>
      <Grid container justify='center'>
        <Grid item sm={11} lg={6}>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={12}>
                <Box mb={2}>
                  <Typography variant='h5' align='center' gutterBottom>
                    INSTRUCCIONES
                  </Typography>
                  <Typography variant='body1' gutterBottom>
                    En cada uno de los 28 cuadrantes seleccionar (en la columna correspondiente), 
                    la cualidad que lo distinga MAS y la que lo distinga MENOS.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default InstruccionesPDP;