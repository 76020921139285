export const API_URI = 'https://pruebas.corporacionnovax.com/sistcloudx/api/v0.1/public/pruebas'

export const getListAllIPVUri = () => {
    return `${API_URI}/ipv`
}

export const getListAllPDPUri = () => {
    return `${API_URI}/pdp`
}

export const getListAllParticipanteUri = (id = '') => {
    return `${API_URI}/participantes`
}

export const createPruebaUri = () => {
    return `${API_URI}`
}