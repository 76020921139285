import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from '@material-ui/core'
import red from '@material-ui/core/colors/red';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    textAlign: 'justify',
    color: theme.palette.text.primary,
  },
  paperError: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    textAlign: 'justify',
    color: theme.palette.text.primary,
    background: red[100]
  }
}));

function ItemCuadernillo(props) {
  const classes = useStyles();
  const {register, setValue, errors, trigger, defaultValue} = props;
  const {pregunta, id_ipv} = props.pregunta;
  const respuestas = props.pregunta.detalle;

  const [respuesta, setRespuesta] = useState(defaultValue);

  const handleRespuesta = e => {
    const {value} = e.target;
    setRespuesta(value);
    setValue(`preguntas.${id_ipv}`, value)
    if (errors.preguntas) {
      trigger(`preguntas.${id_ipv}`);
    }
  };
  
  useEffect(() => {
    register(
      { name: `preguntas.${id_ipv}` },
      { required: true }
    );
    
    setValue(`preguntas.${id_ipv}`, defaultValue);
  }, [register, id_ipv, defaultValue, setValue]);

  return (
    <Grid container justify='center'>
      <Grid item sm={11} lg={6}>
        <Paper className={errors.preguntas && errors.preguntas[id_ipv] ? classes.paperError : classes.paper}>
          <Grid container>
            <Grid item xs={12}>
              <Box mb={2}>
                <Typography variant='body1'>
                  {pregunta}
                </Typography>
              </Box>
            </Grid>
            
            <Grid item xs={12}>
              <RadioGroup 
                name={`preguntas.${id_ipv}`}
                value={respuesta}
                onChange={handleRespuesta} >
                {
                  respuestas.map((resp) => <FormControlLabel 
                    key={resp.id_detalle_ipv} 
                    value={`${resp.id_detalle_ipv}`} 
                    control={<Radio color='primary'/>} 
                    label={resp.respuesta} />)
                }
              </RadioGroup>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default ItemCuadernillo;