import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Box,
  Typography
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    textAlign: 'justify',
    color: theme.palette.text.primary,
  },

}));

function InstruccionesCuadernillo() {
  const classes = useStyles();

  return (
    <div>
      <Grid container justify='center'>
        <Grid item sm={11} lg={6}>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={12}>
                <Box mb={2}>
                  <Typography variant='h5' align='center' gutterBottom>
                    INSTRUCCIONES
                  </Typography>
                  <Typography variant='body1' gutterBottom>
                    En las secciones siguientes encontrará cierto número de situaciones y de preguntas 
                    seguidas de varias respuestas posibles.
                  </Typography>
                  <Typography variant='body1' gutterBottom>
                    Se le pide que escoja una alternativa para cada pregunta, la que de manera espontánea 
                    le parezca preferible.
                  </Typography>
                  <Typography variant='body1' gutterBottom>
                    En algunas cuestiones estará usted personalmente implicado; por ejemplo:
                  </Typography>
                  <Typography variant='body1' gutterBottom>
                    - Si usted pudiera elegir el tipo de vivienda de manera completamente libre, ¿qué preferiría?
                  </Typography>
                  <Typography variant='body1' component='div' gutterBottom>
                    <ol type='A'>
                      <li>Una casa de campo con un gran jardín.</li>
                      <li>Un lujoso apartamento en una gran ciudad.</li>
                      <li>Una casa situada en una calle tranquila de una pequeña ciudad.</li>
                    </ol>
                  </Typography>
                  <Typography variant='body1' gutterBottom>
                    Otras se refieren a personas ajenas,como:
                  </Typography>
                  <Typography variant='body1' gutterBottom>
                    - J... está comprando e un gran almacén cuando advierte que alguien esconde discretamente un disco bajo su suéter. ¿Que hará J...?
                  </Typography>
                  <Typography variant='body1' component='div' gutterBottom>
                    <ol type='A'>
                      <li>Avisar del hecho al primer vendedor que encuentre.</li>
                      <li>No hacer  nada porque piensa que no es cosasuya.</li>
                      <li>Indicar al infractor con el gesto o de palabra que ha visto lo que ha hecho.</li>
                    </ol>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid container justify='center'>
        <Grid item sm={11} lg={6}>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={12}>
                <Box mb={2}>
                  <Typography variant='h5' align='center' gutterBottom>
                    ALGUNAS OBSERVACIONES IMPORTANTES
                  </Typography>

                  <Typography variant='body1' component='ul' gutterBottom>
                      <li>
                        Responda lo más <strong>espontáneamente y sinceramente</strong> con la contestación
                        que se le ocurra inmediatamente al leer las alternativas que se proponen. Conteste rápidamente, sin reflexionar demasiado.
                      </li>
                      <li>
                        Responda a <strong>todas</strong> las preguntas. Es posible que en algunos casos ninguna de las contestaciones
                        propuestas le convenza del todo; esfuércese entonces por elegir aquella que crea mejor entre las alternativas que se ofrecen.
                      </li>
                      <li>
                        No hay límite de tiempo, pero no emplee demasiado rato en cada pregunta.
                      </li>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default InstruccionesCuadernillo;