import React, {useState, useEffect} from 'react'
import { useForm } from 'react-hook-form';

import { 
  Typography,
  Box,
  CircularProgress,
  Snackbar
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import InstruccionesPDP from './InstruccionesPDP'
import ItemPDP from './ItemPDP'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Cuadernillo(props) {
  const {
    items,
    validate, 
    setValidate, 
    setValid, 
    data, 
    handleSaveData
  } = props;
  const {
    register, 
    setValue,
    getValues,
    trigger, 
    //watch,
    errors
  } = useForm({
    mode: "all"
  });
  const [openAlert, setOpenAlert] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  useEffect (() => {
      async function validateForm() {
        if(validate) {
          setValidate(false);
          handleSaveData(getValues().cualidades);
          await trigger();
          setValid(errors.cualidades ? false : true, getValues().cualidades);
          setOpenAlert(errors.cualidades ? true : false);
        }
      }
      validateForm();
  }, [validate]);
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  //console.log('Cualidades: ', watch('cualidades'));
  //console.log('Error: ', errors.cualidades);
  //console.log('Error: ', errors.cualidades ? errors.cualidades[1] : 'nada');
  //console.log('Data: ', data);

  return (
    <div>
      <InstruccionesPDP />
      
      <form noValidate autoComplete="off">
        {
          items.length > 0
            ? items.map((item) => <ItemPDP 
                key={`pdp_${item.id_pdp}`}
                cuadrante={item}
                defaultValue={data[item[0].cuadrante] || ''}
                register={register}
                setValue={setValue}
                errors={errors}
                trigger={trigger} />
              )
            : <Box display="flex" alignItems="center" justifyContent="center" >
                <CircularProgress />
                <Typography variant='body1' color='primary'>&nbsp;&nbsp;Cargando Preguntas</Typography>
              </Box>
        }
      </form>
      
      <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Por favor llene todos los cuadrantes para continuar
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Cuadernillo;