import React from 'react';

import {ThemeProvider} from '@material-ui/core/styles'
import theme from './theme/config'
import CssBaseline from '@material-ui/core/CssBaseline';

import {Provider} from 'react-redux'
import generateStore from './redux/store'

import Pruebas from './Pruebas';

function App() {

  const store = generateStore()

  return (
    <ThemeProvider theme={theme}>
      <Provider store= {store}>
        <CssBaseline />
          <Pruebas />
      </Provider>
    </ThemeProvider>
  );
}

export default App;
