import axios from 'axios';
import { getListAllIPVUri } from '../constants/ApiRoutes';

//Actions
const GET_IPV = 'GET_IPV'

//Reducer
const INIT_STATE = {
  IPVList: []
}

export default function reducer(state = INIT_STATE, action) {
  switch(action.type) {
    case GET_IPV:
      return {
        ...state,
        IPVList: action.payload
      }
      default:
        return state;
  }
}

//Action Creators
export function getIPV(payload) {
  return { 
    type: GET_IPV,
    payload
  }
}

//Side Effects
export const getAllIPV = () => async (dispatch) => {
  try {
    const res = await axios.get(getListAllIPVUri())
    dispatch(getIPV(res.data))
  } catch (error) {
    console.log(error)
  }
}