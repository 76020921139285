import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {
    AppBar,
    Toolbar,
    Typography,
    Icon,
    Hidden
} from '@material-ui/core'

import Logo from '../assets/img/logo_novax_blanco.png'

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  logo: {
    maxHeight: `calc(${theme.mixins.toolbar.minHeight}px - 20px)`,
    marginRight: theme.spacing(2)
  },
  contact: {
    marginLeft:theme.spacing(1),
    marginRight:theme.spacing(2)
  }
}));

function Nav() {
    const classes = useStyles();

    return (
        <AppBar position="fixed">
          <Toolbar>
            <img src={Logo} alt="logo" className={classes.logo} />
            <Typography variant="h6" className={classes.title}>
              Pruebas Psicolaborales
            </Typography>

            <Hidden smDown>
              <Icon>phone</Icon>
              <Typography variant="body2" className={classes.contact}>01 6449279</Typography>
              
              <Icon>email</Icon>
              <Typography variant="body2" className={classes.contact}>contacto@novax.com.pe</Typography>
              
              <Icon>language</Icon>
              <Typography variant="body2" className={classes.contact}>www.novax.com.pe</Typography>
            </Hidden>
          </Toolbar>
        </AppBar>
    );
}

export default Nav;