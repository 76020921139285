import React, {useEffect} from 'react'
import { useForm } from 'react-hook-form';

import { 
  Typography,
  Box,
  CircularProgress,
  Snackbar
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import InstruccionesCuadernillo from './InstruccionesCuadernillo'
import ItemCuadernillo from './ItemCuadernillo'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Cuadernillo(props) {
  const {
    items,
    validate, 
    setValidate, 
    setValid, 
    data, 
    //handleSaveData
  } = props;
  const {
    register, 
    setValue, 
    getValues,
    trigger, 
    errors
  } = useForm({
    mode: "all"
  });
  const [openAlert, setOpenAlert] = React.useState(false);

  useEffect (() => {
      async function validateForm() {
        if(validate) {
          setValidate(false);
          await trigger();
          setValid(errors.preguntas ? false : true, getValues().preguntas);
          setOpenAlert(errors.preguntas ? true : false);
          //handleSaveData(getValues().preguntas);
        }
      }
      validateForm();
  }, [validate]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  //console.log('Preguntas: ', watch('preguntas'));
  //console.log('Error: ', errors.preguntas ? errors.preguntas[1] : 'nada');
  //console.log('Data: ', data);

  return (
    <div>
      <InstruccionesCuadernillo />
      
      <form noValidate autoComplete="off">
        {
          items.length > 0
            ? items.map((item) => <ItemCuadernillo 
                key={item.id_ipv} 
                pregunta={item} 
                defaultValue={data[item.id_ipv] || ''}
                register={register}
                setValue={setValue}
                errors={errors}
                trigger={trigger} />
              )
            : <Box display="flex" alignItems="center" justifyContent="center" >
                <CircularProgress />
                <Typography variant='body1' color='primary'>&nbsp;&nbsp;Cargando Preguntas</Typography>
              </Box>
        }
      </form>
      
      <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Por favor responda todas las preguntas para continuar
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Cuadernillo;