import React, {useState, useEffect} from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Box,
  TextField,
  Typography,
  MenuItem,
  Snackbar
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import blue from '@material-ui/core/colors/blue';

import {useDispatch, useSelector} from 'react-redux';
import {fetchParticipante} from '../redux/ParticipanteDuck';

import Swal from 'sweetalert2';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    textAlign: 'justify',
    color: theme.palette.text.primary,
  },
  field: {
    padding: theme.spacing(1),
  },
  title: {
    marginTop: theme.spacing(2),
  }

}));

const sexos = [
  {
    value: 'M',
    label: 'Masculino'
  },
  {
    value: 'F',
    label: 'Femenino'
  }
];

const tipoDocumentos = [
  {
    value: 'DNI',
    label: 'D.N.I.'
  },
  {
    value: 'PAS',
    label: 'Pasaporte'
  },
  {
    value: 'PTP',
    label: 'Permiso Temporal de Permanencia'
  },
  {
    value: 'CEX',
    label: 'Carné de Extranjería'
  }
];

function DatosPersonales (props) {
  const classes = useStyles();
  const {validate, setValidate, setValid, data, handleSaveData} = props;
  const [participanteValid, setParticipanteValid] = useState(false);
  const [sexo, setSexo] = useState(data.sexo || 'M');
  const [tipoDocumento, setTipoDocumento] = useState(data.cod_tipo_documento || 'DNI');
  const [openAlert, setOpenAlert] = useState(false);
  const {register, watch, trigger, setValue, errors} = useForm({
    mode: "all"
  });

  const dispatch = useDispatch();
  const stateParticipante = useSelector(state => state.participante.participante);

  const handleChangeSexo = (event) => {
    const {value} = event.target;
    setSexo(value);
    setValue("participante.cod_sexo", value);
  };

  let participante = watch('participante');

  const handleChangeTipoDocumento = (event) => {
    const {value} = event.target;
    setTipoDocumento(value);
    setValue("participante.cod_tipo_documento", value);
    
    setParticipanteValid(false);

    if ((value === 'DNI' && participante.numero_documento.length === 8) ||
        (value !== 'DNI' && participante.numero_documento.length === 9)) {
      dispatch(fetchParticipante(value, participante.numero_documento));
    }
  };

  const handleChangeNumeroDocumento = async (event) => {
    const {value} = event.target;
    
    setParticipanteValid(false);

    if ((tipoDocumento === 'DNI' && value.length === 8) ||
        (tipoDocumento !== 'DNI' && value.length === 9)) {
      dispatch(fetchParticipante(tipoDocumento, value));
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenAlert(false);
  };

  useEffect (() => {
      async function validateForm() {

        if(validate) {
          setValidate(false);
          await trigger();
          setValid(errors.participante ? false : participanteValid && true);
          setOpenAlert(errors.participante ? true : false);
          handleSaveData(participante);
        }
      }
      validateForm();
  }, [validate]);

  useEffect(() => {
    register(
      { name: "participante.cod_sexo" },
      { required: true }
    );
    register(
      { name: "participante.cod_tipo_documento" },
      { required: true }
    );
    
    setValue("participante.cod_sexo", data.sexo || sexo);
    setValue("participante.cod_tipo_documento", data.tipo_documento || tipoDocumento);
  }, [data, sexo, register, setValue]);

  useEffect(() => {
    if (stateParticipante.numero_documento) {
      Swal.fire({
        title: `Estimado ${stateParticipante.nombre}`,
        text: `Ya tienes una prueba registrada el día ${stateParticipante.fecha_crea}, no debes registrar otra.`,
        icon: 'warning',
        confirmButtonText: 'ACEPTAR',
        confirmButtonColor: blue[500],
      });

      setParticipanteValid(false);
    }
    else {
      setParticipanteValid(true);
    }
  }, [stateParticipante])

  return (
    <Grid container justify='center'>
      <Grid item sm={11} lg={6}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={12}>
              <Box mb={2}>
                <Typography variant='h5' align='center' gutterBottom>
                  Datos Personales
                </Typography>
                <form noValidate autoComplete="off">
                  <Grid container>
                    <Grid item xs={12} md={6} className={classes.field}>
                      <TextField 
                        error={errors.participante && errors.participante.nombre && true}
                        id="nombre"
                        name="participante.nombre"
                        inputRef={register({required: "ERROR: Este campo no puede estar vacío"})}
                        label="Apellido(s) y Nombre(s)" 
                        defaultValue={data.nombre || ''}
                        helperText={errors.participante && errors.participante.nombre && errors.participante.nombre.message}
                        required
                        fullWidth />
                    </Grid>
                    <Grid item xs={12} md={3} className={classes.field}>
                      <TextField
                        id="tipo_documento"
                        name="participante.cod_tipo_documento"
                        select
                        label="Tipo de documento"
                        value={tipoDocumento}
                        onChange={handleChangeTipoDocumento}
                        required
                        fullWidth
                      >
                        {tipoDocumentos.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={3} className={classes.field}>
                      <TextField 
                        error={(errors.participante && errors.participante.numero_documento && true) || !participanteValid}
                        id="numero_documento"
                        name="participante.numero_documento" 
                        inputRef={register({
                          required: "ERROR: Este campo no puede estar vacío",
                          minLength: {
                            value: 8,
                            message: 'ERROR: debe tener entre 8 y 9 dígitos'
                          },
                          maxLength: {
                            value: 9,
                            message: 'ERROR: debe tener entre 8 y 9 dígitos'
                          }
                        })}
                        label="Número de documento" 
                        defaultValue={data.numero_documento || ''}
                        helperText={errors.participante && errors.participante.numero_documento && errors.participante.numero_documento.message}
                        type="number"
                        onChange={handleChangeNumeroDocumento}
                        required
                        fullWidth />
                    </Grid>
                    <Grid item xs={6} md={3} className={classes.field}>
                      <TextField 
                        error={errors.participante && errors.participante.edad && true}
                        id="edad"
                        name="participante.edad" 
                        inputRef={register({required: "ERROR: Este campo no puede estar vacío"})}
                        label="Edad" 
                        defaultValue={data.edad || ''}
                        helperText={errors.participante && errors.participante.edad && errors.participante.edad.message}
                        type="number"
                        required
                        fullWidth />
                    </Grid>
                    <Grid item xs={6} md={3} className={classes.field}>
                      <TextField
                        id="sexo"
                        name="participante.sexo"
                        select
                        label="Sexo"
                        value={sexo}
                        onChange={handleChangeSexo}
                        required
                        fullWidth
                      >
                        {sexos.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={6} md={3} className={classes.field}>
                      <TextField 
                        error={errors.participante && errors.participante.telefono && true}
                        id="telefono"
                        name="participante.telefono" 
                        inputRef={register({required: "ERROR: Este campo no puede estar vacío"})}
                        label="Teléfono" 
                        defaultValue={data.telefono || ''}
                        helperText={errors.participante && errors.participante.telefono && errors.participante.telefono.message}
                        type="number"
                        required
                        fullWidth />
                    </Grid>
                    <Grid item xs={6} md={3} className={classes.field}>
                      <TextField 
                        error={errors.participante && errors.participante.correo && true}
                        id="correo"
                        name="participante.correo" 
                        inputRef={register({
                          required: "ERROR: Este campo no puede estar vacío",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "ERROR: correo inválido"
                          }
                        })}
                        label="Correo" 
                        defaultValue={data.correo || ''}
                        helperText={errors.participante && errors.participante.correo && errors.participante.correo.message}
                        type="email"
                        required
                        fullWidth />
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.field}>
                      <TextField 
                        error={errors.participante && errors.participante.nivel_estudios && true}
                        id="nivel_estudios" 
                        name="participante.nivel_estudios"
                        inputRef={register({required: "ERROR: Este campo no puede estar vacío"})}
                        label="Nivel de Estudios Alcanzado" 
                        defaultValue={data.nivel_estudios || ''}
                        helperText={errors.participante && errors.participante.nivel_estudios && errors.participante.nivel_estudios.message}
                        required
                        fullWidth />
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.field}>
                      <TextField 
                        error={errors.participante && errors.participante.actividad_actual && true}
                        id="actividad_actual" 
                        name="participante.actividad_actual"
                        inputRef={register({required: "ERROR: Este campo no puede estar vacío"})}
                        label="Actividad Actual" 
                        defaultValue={data.actividad_actual || ''}
                        helperText={errors.participante && errors.participante.actividad_actual && errors.participante.actividad_actual.message}
                        required
                        fullWidth />
                    </Grid>
                    <Grid item xs={12} className={classes.title}>
                      <Typography variant='h6' align='center' gutterBottom>
                        Experiencia en Ventas
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={10} className={classes.field}>
                      <TextField 
                        id="tipo_actividad" 
                        name="participante.tipo_actividad"
                        inputRef={register}
                        label="Tipo de Actividad" 
                        defaultValue={data.tipo_actividad || ''}
                        fullWidth />
                    </Grid>
                    <Grid item xs={12} md={2} className={classes.field}>
                      <TextField 
                        id="tiempo_actividad" 
                        name="participante.tiempo_actividad"
                        inputRef={register}
                        label="Tiempo" 
                        defaultValue={data.tiempo_actividad || ''}
                        fullWidth />
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      
      <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Por favor introduzca correctamente los datos que se piden!!
        </Alert>
      </Snackbar>
    </Grid>
  );
}

export default DatosPersonales;