import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Box,
  Radio,
  Typography
} from '@material-ui/core'
import red from '@material-ui/core/colors/red';

const useStyles = makeStyles((theme) => ({
  table: {
  },
  container: {
    minWidth: 380,
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    textAlign: 'justify',
    color: theme.palette.text.primary,
  },
  paperError: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    textAlign: 'justify',
    color: theme.palette.text.primary,
    background: red[100]
  }
}));

function ItemPDP(props) {
  const classes = useStyles();
  const {register, setValue, errors, trigger, defaultValue} = props;
  const cualidades = props.cuadrante;
  const [selectedValueMas, setSelectedValueMas] = useState(defaultValue.mas);
  const [selectedValueMenos, setSelectedValueMenos] = useState(defaultValue.menos);

  const handleChangeMas = (event) => {
    const {value} = event.target;

    if(value !== selectedValueMenos) {
      setSelectedValueMas(value);
      setValue(`cualidades.${cualidades[0].cuadrante}.mas`, value);

      if (defaultValue !== '') {
        trigger(`cualidades.${cualidades[0].cuadrante}.mas`);
        trigger(`cualidades.${cualidades[0].cuadrante}.menos`);
      }
    }
  }

  const handleChangeMenos = (event) => {
    const {value} = event.target;

    if(value !== selectedValueMas) {
      setSelectedValueMenos(value);
      setValue(`cualidades.${cualidades[0].cuadrante}.menos`, value);
      
      if (defaultValue !== '') {
        trigger(`cualidades.${cualidades[0].cuadrante}.mas`);
        trigger(`cualidades.${cualidades[0].cuadrante}.menos`);
      }
    }
  }
  
  useEffect(() => {
    register(
      { name: `cualidades.${cualidades[0].cuadrante}.mas` },
      { required: true }
    );
    register(
      { name: `cualidades.${cualidades[0].cuadrante}.menos` },
      { required: true }
    );
    
    setValue(`cualidades.${cualidades[0].cuadrante}.mas`, defaultValue.mas);
    setValue(`cualidades.${cualidades[0].cuadrante}.menos`, defaultValue.menos);
  }, [register, cualidades, defaultValue, setValue]);

  return (
    <Grid container justify='center'>
      <Grid item sm={11} lg={6} className={classes.container}>
        <Paper className={errors.cualidades && errors.cualidades[cualidades[0].cuadrante] ? classes.paperError : classes.paper}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant='body1'>{cualidades[0].cuadrante}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant='body1' align='center'>MAS</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant='body1' align='center'>MENOS</Typography>
            </Grid>
          </Grid>

          { 
            cualidades.map((cualidad) => (
              <Grid container alignItems='center' key={cualidad.id_pdp}>
                <Grid item xs={6}>
                  <Typography variant='body1'>{cualidad.cualidad}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Box 
                    display="flex" 
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Radio
                      checked={
                        selectedValueMas === `${cualidad.id_pdp}` && 
                        selectedValueMenos !== `${cualidad.id_pdp}`
                      }
                      onChange={handleChangeMas}
                      value={`${cualidad.id_pdp}`}
                      name={cualidad.cualidad}
                      color='primary'
                      inputProps={{ 'aria-label': 'MAS' }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box 
                    display="flex" 
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Radio
                      checked={
                        selectedValueMenos === `${cualidad.id_pdp}` &&
                        selectedValueMas !== `${cualidad.id_pdp}`
                      }
                      onChange={handleChangeMenos}
                      value={`${cualidad.id_pdp}`}
                      name={cualidad.cualidad}
                      color='primary'
                      inputProps={{ 'aria-label': 'MENOS' }}
                    />
                  </Box>
                </Grid>
              </Grid>
            ))
          }
        </Paper>
      </Grid>
    </Grid>
  );
}

export default ItemPDP;